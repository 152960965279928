import React from 'react';
import '../../styles/scss/partials/blog.scss';
import BlogItem from '../../components/blog-components/BlogItem';
import blogData from '../../data/blogData.json'
import Layout from '../../components/Layout';
import { useTheme } from '../../contexts/ThemeContext';
import SocialIconsFixed from '../../components/core-components/SocialIconsFixed';


const BlogComponent = () => {
  const blogArray = Object.values(blogData);
  const {darkMode} = useTheme();

  return (
    <Layout>
      <section>
        <div className={`blogHeader ${darkMode ? 'dark-mode' : ''}`}>
          <h1 className='blogHeading'>The newest articles, discussions, topics</h1>
        </div>
        <br />

        {/* ARTICLES */}
        <section style={{ top: '-40%', fontFamily: 'Marvin' }} className={`blog-collection ${darkMode ? 'dark-mode' : ''}`}>
          <div className="band">
            {blogArray.map((blogItem, index) => (
              <BlogItem key={index} blog={blogItem} />
            ))}
          </div>
        </section>

      </section>
      <SocialIconsFixed/>
    </Layout>
  );
};

export default BlogComponent;
