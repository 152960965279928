import React, {useEffect, useState} from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import swedishTrips from '../../../data/booking-data/swedishTrips.json';
import BookingTemplate from '../template/CarouselViewTemplate';


const SwedishDelights = () => {
    return(
      <BookingTemplate categoryTitle="Experience Swedish Locations" categoryTrips={Object.values(swedishTrips)}/>

  )
};

export default SwedishDelights