import React, { useState } from 'react';
import BookingCard from '../../core-components/BookingCard';
import { useTheme } from '../../../contexts/ThemeContext';

const MobileBookingView = ({ categoryTrips, categoryTitle }) => {
  const [visibleTrips, setVisibleTrips] = useState(3); // Number of initially visible trips
  const { darkMode } = useTheme(); // Use the theme context to get the dark mode state

  const handleViewMore = () => {
    setVisibleTrips((prevVisibleTrips) => prevVisibleTrips + 3);
  };

  const handleViewLess = () => {
    setVisibleTrips(3);
  };

  return (
    <div className={`mobileOffers ${darkMode ? 'dark-mode' : ''}`}>
      <div className='drop-shape'>
        <h2 className='ourFavourites'>{categoryTitle}</h2>
      </div>
      <div className={`mobileSectionOffers ${darkMode ? 'dark-mode' : ''}`}>
        {categoryTrips.slice(0, visibleTrips).map((city) => (
          <BookingCard
            key={city.id}
            backgroundImage={city.backgroundImage}
            pageLink={city.pageLink}
            title={city.title}
            price={city.details?.price}
            duration={city.details?.duration}
            description={city.details?.description}
            seasons={city.details?.seasons}
          />
        ))}
      </div>

      {visibleTrips < categoryTrips.length && (
        <div className='btn-container'>
          <button className={`more-trips-btn ${darkMode ? 'dark-mode' : ''}`} onClick={handleViewMore}>
            View More
          </button>
        </div>
      )}

      {visibleTrips > 3 && (
        <div className='btn-container'>
          <button className={`more-trips-btn ${darkMode ? 'dark-mode' : ''}`} onClick={handleViewLess}>
            View Less
          </button>
        </div>
      )}
    </div>
  );
};

export default MobileBookingView;