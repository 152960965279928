import React from 'react';
import { FaArrowRight } from "react-icons/fa";
import '../../styles/scss/partials/google-rew.scss';

const GoogleReviewCard = ({ name, stars, comment, profilePic }) => (
  <div className="google-card">
    <div className="google-profile-pic">
      <img src={profilePic} alt={`${name}'s Profile Pic`} />
      <div className="google-stars">
        {renderStars(stars)}
      </div>
    </div>
    <div className="google-card-header">
      <h3>{name}</h3>
    </div>
    <div className="google-card-body">
      <p>{comment}</p>
    </div>
  </div>
);

// Function to render SVG stars based on rating
const renderStars = (rating) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  const stars = [];

  for (let i = 0; i < fullStars; i++) {
    stars.push(<Star key={i} />);
  }

  if (halfStar) {
    stars.push(<HalfStar key="half" />);
  }

  for (let i = 0; i < emptyStars; i++) {
    stars.push(<EmptyStar key={i + fullStars + 1} />);
  }

  return stars;
};

const Star = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="#0088cc">
    <path d="M12 2l2.65 8.18H22l-7.24 5.27L17.5 22 12 17.35 6.5 22l2.74-6.55L2 10.18h7.35L12 2z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

const HalfStar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="#0088cc">
    <path fill="none" d="M0 0h24v24H0V0z"/>
    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
  </svg>
);

const EmptyStar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="#0088cc">
    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/>
    <path d="M0 0h24v24H0z" fill="none"/>
  </svg>
);

const GoogleReviewSection = ({ reviews }) => {
  const review = reviews[0];

  return (
    <div className="google-review-section">
      <div className="google-card-container">
        <GoogleReviewCard {...review} />
      </div>
      <div className="google-heading-container">
        <h2>Find out what our users think about us</h2>
        <a href="https://maps.app.goo.gl/7QBc1j3QgfvqtJkL9" className="explore-link"><FaArrowRight /> Explore More</a>
        <div className="google-link">
          {/* Google icon */}
          <a href='https://maps.app.goo.gl/7QBc1j3QgfvqtJkL9'><img src='https://cdn4.iconfinder.com/data/icons/logos-brands-7/512/google_logo-google_icongoogle-512.png' alt="Google Icon" /></a>
          <div className="google-stars">{renderStars(4.5)}</div>
        </div>
      </div>
    </div>
  );
};

export default GoogleReviewSection;
