import React from 'react';
import '../../styles/scss/partials/workerCard.scss';
import { FaWhatsapp, FaLinkedin } from 'react-icons/fa';

const WorkerCard = ({ name, designation, email,link, imageUrl, location, linkedin }) => {
  return (
    <div className="workerCard">
      <div className="banner">
        <img src={imageUrl} alt={name} />
      </div>
      <div className="menu">
        <div className="opener">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <h2 className="name">
        {name}
      </h2>
      {linkedin && (
        <div className="linkedin">
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <FaLinkedin style={{ color: '#007ad6', verticalAlign: 'middle' }} />
          </a>
        </div>
      )}
      {/*<div className="title">{designation}</div>*/}
      <div className="actions">
        <div className="follow-btn">
        <a href={link}>
          <button>
            <FaWhatsapp /> 
            Contact now
          </button>
          </a>
        </div>
      </div>
      <div className="desc">
        <span>Email:</span> {email}
      </div>
      <div className="location">{location}</div>
    </div>
  );
};

export default WorkerCard;
