import React from 'react';
import { useTheme } from '../../contexts/ThemeContext'
import '../../styles/scss/styles.scss';
import LandingSection from '../../components/our-tours/landingSections/LandingSection'
import Slider from '../../components/core-components/Slider';
import WhyUs from '../../components/our-tours/landingSections/WhyUs'
import NorwegianDelights from '../../components/carousel-view-offers/views/NorwegianDelights'
import SpecialOffers from '../../components/carousel-view-offers/views/SpecialOffers';
import Layout from '../../components/Layout';
import HottestOffers from '../../components/carousel-view-offers/views/HottestOffers';
import GoogleReviewGrid from '../../components/core-components/GoogleReviews';
import reviewsData from '../../data/reviews.json'



const Home = () => {


    return (

        <Layout>
            <LandingSection />
            {/*<Review/>*/}
            <HottestOffers />
            <NorwegianDelights />
            <SpecialOffers />
            <WhyUs />
            {/*<Slider />*/}
            <GoogleReviewGrid reviews={reviewsData} />
        </Layout>


    );
};
export default Home;