import React from 'react';
import '../../../styles/scss/partials/mobLanding.scss';
import QuickEnquiry from '../../core-components/QuickEnquiry';
import { FaFacebook, FaTwitter, FaInstagram, FaTiktok } from 'react-icons/fa';

const MobileLanding = () => {
    return (
        <div className="mobLanding">
            <h1>Welcome to Nordix Trip</h1>
            <p>Explore North Europe in a different way, without limits</p>
            

            {/*<div className="mobileAbout" id="about">
                <p>Nordix Trip is your ultimate travel companion, offering a seamless experience with unmatched benefits. Explore breathtaking destinations, curated just for you, and enjoy the following cool stuff:</p>
    </div>*/}

            <div className="mobile-card-container">
                <div className="card">
                    <img src="/photo/Northern_Light_HomeHeader1.jpg" alt="Card Image" />
                    <h3>Hiking</h3>
                </div>
                <div className="card">
                    <img src="/photo/Helsinki_Cathedral_HomeHeader2.jpg" alt="Card Image" />
                    <h3>City Tours</h3>
                </div>
                <div className="card">
                    <img src="https://img.freepik.com/free-photo/woman-enjoying-beautiful-rural-surroundings_23-2149125483.jpg?w=360&t=st=1703677596~exp=1703678196~hmac=c9439f8fe956e08e35f2d6c12361afb79da944924a808878dab39f30a7394311" alt="Card Image" />
                    <h3>Personalized Trips</h3>
                </div>
                <div className="card">
                    <img src="/photo/Santa_HomeHeader1.jpg" alt="Card Image" />
                    <h3>Exclusive packages</h3>
                </div>
            </div>
           
            <QuickEnquiry/>
        </div>
    );
}

export default MobileLanding;
