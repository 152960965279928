import React from 'react'
import CardContainer from '../template/NonCarouselContainer'
import BookingCard from '../../core-components/BookingCard'
import headerTrips from '../../../data/booking-data/headerTrips.json'

const TopOffers = () => {
  const topThreeOffers = Object.values(headerTrips).slice(0, 3);
  return (
    <div>
      <CardContainer>
        {topThreeOffers.map((offer) => (
          <BookingCard
            key={offer.id}
            backgroundImage={offer.backgroundImage}
            pageLink={offer.pageLink}
            title={offer.title}
            description={offer.details.description}
            duration={offer.details.duration}
            price={offer.details.price}
          />
        ))}
      </CardContainer>
    </div>
  )
}

export default TopOffers