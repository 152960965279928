import React from 'react';
import '../../styles/css/social-icons.css';
import { FaTwitter, FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';

const SocialIconsFixed = () => {
  return (
    <>
    <nav class="social">
  
 
          <ul>
          <li><a href="https://www.instagram.com/nordixtrip/">Instagram &nbsp; <FaInstagram/></a></li>
          <li><a href="https://www.facebook.com/profile.php?id=61556110481738">Facebook &nbsp; <FaFacebook/></a></li>
              <li><a href="https://twitter.com/nordixtrip">Twitter &nbsp; <FaTwitter/></a></li>
            <li><a href="">Linkedin &nbsp; <FaLinkedin/></a></li>
            
              </ul>
      </nav>
      </>
  )
}

export default SocialIconsFixed