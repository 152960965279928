import React from 'react';
import '../../styles/css/footer.css';
import { Link } from 'react-router-dom';

import { FaFacebook, FaTwitter } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import MapComponent from './MapComponent';




const Footer = () => {
  return (
    <section className='footer'>
      <div className='footer-content'>
        <h2>Nordix Trip</h2>
        <p>The best way of experiencing North Europe</p>
        <div className='icons'>
          <li><a href='https://www.facebook.com/profile.php?id=61556110481738'><FaFacebook/></a></li>
          <li><a href=''><FaTiktok/></a></li>
          <li><a><FaTwitter/></a></li>
          <li><a href='https://www.instagram.com/nordixtrip/'><AiFillInstagram/></a></li>

        </div>
      </div>
      <div className='footer-content'>
        <h4>Useful links</h4>
        <li><Link to='/'>Home</Link></li>
        <li><Link to='/our-tours'>Our Tours</Link></li>
        <li><Link to='/about'>About</Link></li>
        <li><Link to='/blog'>Blog</Link></li>
      </div>
     {/*} <div className='footer-content'>
        <h4>Privacy</h4>
        <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
        {/*
        <li><Link>Report a problem</Link></li>
        <li><Link>Additional info</Link></li>
        <li><Link>Contact</Link></li>
  </div>*/}
      
      <div className='footer-content'>
        <h4>Help</h4>
        <li><Link to='/contact'>Contact us</Link></li>
        <li><Link>Our Policy</Link></li>
      </div>
      <div className='footer-content'>
        <MapComponent location='Helsinki, Finland'/>
</div>

    </section>
  );
};

export default Footer;
