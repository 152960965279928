import React from 'react';
import '../../styles/scss/styles.scss';
import {Link} from 'react-router-dom'
import { useTheme } from '../../contexts/ThemeContext';


const BlogItem = ({ blog }) => {
  const { title, author, link, imageUrl, content, isFirstItem } = blog;
  const {darkMode} = useTheme();

  return (
    <div className={`item ${isFirstItem ? 'item-1' : ''}`}>
      <Link to={link} className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="thumb" style={{ backgroundImage: `url(${imageUrl})` }}></div>
        <article>
          <h1>{title}</h1>
          {content && <p>{content}</p>}
          <span>{author}</span>
        </article>
      </Link>
    </div>
  );
};

export default BlogItem;
