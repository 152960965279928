import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../styles/css/slider.css';
import '../../styles/scss/styles.scss'

import React, { useState } from 'react';
import Slider from 'react-slick';

const SponsorSlider = () => {

  //slider settings
  const [settings] = useState({
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 100,
    arrows: false
  });
  //logo data imgs
  const logos = [
    'https://cdn-icons-png.flaticon.com/512/2504/2504944.png', //tripadvisor
    'https://foodtech-files.s3-eu-west-2.amazonaws.com/f46990ebca34af24993aec7d457fdad98cff8cdaae6d783c9be3c08805dadde2', //glovo
    'https://seeklogo.com/images/H/hilton-hotels-resorts-logo-9B26B29074-seeklogo.com.png', //hilton hotels
    'https://pbs.twimg.com/profile_images/1531984771001331714/GEGMC3zQ_400x400.jpg', //waldorf astoria
    'https://i.pinimg.com/1200x/63/d8/53/63d853ee9f69aee00f127db0fef61455.jpg',
    'https://play-lh.googleusercontent.com/_CimS1uQUeCk2fKMD7K-RBLeIa-z0SUEE7zH75h66t5yPrVEbrYiUBckw9QRrcTaZuA',
    'https://1000logos.net/wp-content/uploads/2017/03/Lufthansa-symbol.jpg', //lufthansa
    'https://pbs.twimg.com/profile_images/1659099551335084035/go5KyJYL_400x400.jpg', //air france
    'https://www.airline-empires.com/uploads/gallery/album_2984/gallery_93101_2984_41476.png', //air austria
    'https://i.pinimg.com/736x/03/5b/01/035b017a41ac5a828819039a92869a93.jpg', //swiss air
  ];



  return (
    <div className="slider-container">
      <h2 className="ourFavourites" style={{ paddingBottom: '1.7em' }}>Proudly Supported by</h2>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="logo-container">
            <img src={logo} alt='logo' className="logo-img" />
          </div>
        ))}
      </Slider>
    </div>

  );
};
export default SponsorSlider;