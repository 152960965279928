import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import '../../styles/css/footer.css';

const MapComponent = ({ location = 'Helsinki' }) => {
  const [mapData, setMapData] = useState([]);

  // Fetching from OpenStreetMap 
  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?q=${location}&format=json`
        );
        setMapData(response.data);
      } catch (error) {
        console.error('Error fetching map data:', error);
      }
    };

    fetchMapData();
  }, [location]);

  const coordinates =
    mapData.length > 0
      ? [parseFloat(mapData[0].lat), parseFloat(mapData[0].lon)]
      : [60.1979424, 24.928634]; // Default coordinates for Nordix Trip

  return (
    <MapContainer
      center={coordinates}
      zoom={17} // Adjust the zoom level to fit your needs
      style={{ height: '500px', width: '100%' }} // Adjust the width as needed
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={coordinates}>
        <Popup>
          <div>
            <p>{location}</p>
            <a
              href={`https://www.google.com/maps/place/Nordix+Trip/@${coordinates[0]},${coordinates[1]},17z`}
              target="_blank"
              rel="noopener noreferrer"
              className="map-link"
            >
              View on Google Maps
            </a>
          </div>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;
