import React from 'react';
import '../../styles/scss/styles.scss';
import '../../styles/css/links.css';

import AboutComponent from '../../components/about-us/AboutUsComponent';
import Layout from '../../components/Layout';



const About = () => {
  return (
    <Layout>
    <div>
      <AboutComponent/>
    </div>
    </Layout>
  );
};

export default About;
