 /*const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('/api/contact/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Form submitted successfully');
                // Reset form or perform any other actions
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };*/



    import React, { useState } from 'react';
    import Input from '@mui/material/Input';
    import Button from '@mui/material/Button';
    import PhoneInput from 'react-phone-input-2';
    import 'react-phone-input-2/lib/style.css';
import FaqContact from '../contactComponents/FaqContact';
import QuickEnquiry from './QuickEnquiry';
    
    const ContactForm = () => {
      const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        topic: '',
        message: '',
      });
    
      const [submitted, setSubmitted] = useState(false);
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await fetch('https://nordixtrip-contact.onrender.com/api/contact', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            console.log('Form submitted');
            setFormData({ name: '', email: '', mobile: '', topic: '', message: '' });
            setSubmitted(true);
          } else {
            console.error('Error with submission');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handlePhoneChange = (value, country, e, formattedValue) => {
        setFormData({ ...formData, mobile: formattedValue });
      };
    
      const transparentInputStyle = {
        backgroundColor: 'transparent',
        border: '1px solid white', // Add border for visibility
        color: '#ddd', // Text color
        padding: '10px', // Padding for input fields
        marginBottom: '15px', // Margin between input fields
        fontWeight:'700',
        fontSize:'15px'

      };
    
      const buttonStyle = {
        backgroundColor: '#124076', // Background color of the button
        color: 'white', // Text color of the button
        padding: '10px 20px', // Padding of the button
        borderRadius: '5px', // Border radius of the button
        cursor: 'pointer', // Cursor style
        border: 'none', // Remove button border
      };
      const phoneInputStyles = {
        container: {
          width: '100%',
          marginTop: '20px', 
        },
        input: {
          width: '88.5%',
          padding: '8px',
          borderRadius: '5px',
          margin: '35px',
        },
      };
    
      return (
        <div className='formWrapper'>
          <div className="message-form-container">
            <div className="message-form">
              <h3>Contact Us</h3>
              {submitted ? (
                <div className="confirmation-message">
                  <p>Thank you for contacting us! We'll get back to you soon.</p>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="fancy-form">
                <Input
                    name="topic"
                    placeholder="Topic"
                    type="text"
                    value={formData.topic}
                    onChange={handleChange}
                    fullWidth
                    style={transparentInputStyle}
                  />
                  <Input
                    name="name"
                    placeholder="Your Name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    required
                    style={transparentInputStyle}
                  />
    
                  <Input
                    name="email"
                    placeholder="Your Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    required
                    style={transparentInputStyle}
                  />
    
                  <PhoneInput
                    country={'de'}
                    value={formData.mobile}
                    onChange={handlePhoneChange}
                    inputStyle={phoneInputStyles.input}
                  />
    
                  
    
                  <Input
                    name="message"
                    placeholder="Your Message"
                    type="text"
                    value={formData.message}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={4}
                    style={{ ...transparentInputStyle, minHeight: '100px' }}
                  />
    
                  <Button variant="contained" type="submit" style={buttonStyle}>
                    Send Message
                  </Button>
                </form>
              )}
            </div>
          </div>
          <div className="side-banner">
            <h4 className='logo'>Nordix Trip</h4>
            <p>Travelling has no limits</p>
            <FaqContact/>
          </div>
          <QuickEnquiry/>
        </div>

      );
    };
    
    export default ContactForm;
    
    
    