import React from 'react'
import '../../styles/scss/partials/mobNav.scss';
import {Link} from 'react-router-dom'
import ThemeToggle from '../core-components/ThemeToggle';

const MobNav = () => {

  return (
    <div className="navContainer">
    <input id="toggle" type="checkbox"/>
    <label className="toggle-container" for="toggle">
      <span className="button button-toggle"></span>
    </label>
    <nav class="nav">
      <Link to='/' className='nav-item'>Home</Link>
      <Link className='nav-item' to='/our-tours'>Our Tours</Link>
      <Link to='/contact' className='nav-item'>Contact</Link>
      <Link to='/about' className='nav-item'>About Us</Link>
      <Link to='/blog' className='nav-item'>Blog</Link>
      <br/>
    </nav>
    <label className='toggle-container'>
       <ThemeToggle/>
    </label>
    
  </div>
  )
}

export default MobNav;