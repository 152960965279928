import React from "react";
import '../../styles/scss/styles.scss';
import HottestOffers from '../carousel-view-offers/views/HottestOffers'
import DenmarkOffers from "../carousel-view-offers/views/DenmarkOffers";
import EstoniaOffers from "../carousel-view-offers/views/EstoniaTopPicks";
import SpecialOffers from "../carousel-view-offers/views/SpecialOffers";
import SwedishDelights from "../carousel-view-offers/views/SwedishDelights";
import Layout from '../Layout'
import LandingTripSection from "../trip-info-components/LandingTripSect";


const OurToursComponent = () => {
    return(
        <Layout>
        <div>
        <LandingTripSection 
        backgroundImage="https://wallpaperbat.com/img/243674-nordic-landscape-wallpaper-top-free-nordic-landscape.jpg"
        title="Our tours"
        targetScrollId="our-tours"/>
        <section id="our-tours">
            <HottestOffers/>
        </section>

        <section style={{justifyContent:'center', alignItems:'center', margin:'auto'}}>
            <DenmarkOffers/>
        </section>

      
        <section>
            <SpecialOffers/>
        </section>

    
        <section>
            <EstoniaOffers/>
        </section>

        <section>
            <SwedishDelights/>
        </section>

            
           
        

        
</div>
</Layout>

    )
}
export default OurToursComponent;