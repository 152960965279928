import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/scss/partials/bookingCards.scss';
import { FaBed } from "react-icons/fa";
import { FaCar } from "react-icons/fa6";
import { ImBinoculars } from 'react-icons/im';
import { FaUtensils } from 'react-icons/fa';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { LuPlane } from "react-icons/lu";
import Tooltip from './Tooltip';
import { useTheme } from '../../contexts/ThemeContext';

const BookingCard = ({ backgroundImage, pageLink, title, description, duration, price }) => {
  const [isMobile, setIsMobile] = useState(false);
  const { darkMode } = useTheme();

  const formatDuration = () => {
    if (duration && duration.days && duration.nights) {
      return (
        <>
          {duration.days} days<br />
          {duration.nights} nights
        </>
      );
    } else {
      return 'Duration not available';
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 600;
      setIsMobile(newIsMobile);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  

  return (
    <div className={`container${darkMode ? ' dark-mode' : ''}`}>
      <Link to={pageLink} className="post">
        <div className={`header_post${darkMode ? ' dark-mode' : ''}`}>
          <img src={backgroundImage} alt={`Background for ${title}`} />
        </div>
        <div className={`body_post${darkMode ? ' dark-mode' : ''}`}>
          <div className={`post_content${darkMode ? ' dark-mode' : ''}`}>
            <div className={`textIconWrapper${darkMode ? ' dark-mode' : ''}`}>
              <div className='text-row'>
                <h1 className={`h1${darkMode ? ' dark-mode' : ''}`}>{title}</h1>
                <p className={`p${darkMode ? ' dark-mode' : ''}`}>
                 {description}
                </p>
              </div>
              <div className={`icon-row${darkMode ? ' dark-mode' : ''}`}>
                <Tooltip text="Local Transport">
                  <FaCar />
                </Tooltip>
                <Tooltip text="Accommodation">
                  <FaBed />
                </Tooltip>
                <Tooltip text="Sightseeing">
                  <ImBinoculars />
                </Tooltip>
                <Tooltip text="Meals Included">
                  <FaUtensils />
                </Tooltip>
                <Tooltip text="Certified guides">
                  <AiFillSafetyCertificate />
                </Tooltip>
                <Tooltip text="Domestic transport">
                  <LuPlane />
                </Tooltip>
              </div>
            </div>
            <div className={`container_infos${darkMode ? ' dark-mode' : ''}`}>
              <div className={`price-item${darkMode ? ' dark-mode' : ''}`}>
                <span>Price:</span>
                {price ? (
                  <>
                    {price.discounted && price.discounted !== "€0" ? (
                      <>
                        <del style={{ color: 'red' }}>{price.original}</del> {price.discounted}
                      </>
                    ) : (
                      price.original
                    )}
                  </>
                ) : (
                  "Price details not available"
                )}
              </div>
              <div className={`price-item${darkMode ? ' dark-mode' : ''}`}>
                <span>Duration:</span>
                {formatDuration()}
              </div>
              <div className={`button-container${darkMode ? ' dark-mode' : ''}`}>
                <button className={`custom-button${darkMode ? ' dark-mode' : ''}`}>
                  <Link to={pageLink}>
                    View more
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BookingCard;
