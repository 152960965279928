import React from 'react';
import '../../styles/scss/styles.scss';
import '../../styles/css/links.css';
import { FaFacebook, FaTwitter, FaLinkedin, FaTiktok } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { MdAlternateEmail } from "react-icons/md";
import WorkerCard from './WorkerCard';
import LandingTripSection from '../trip-info-components/LandingTripSect';
import MissionComponent from './Mission';




const AboutComponent = () => {
  const locationsData = [
    { country: 'FINLAND', number: '+358 50-335-8000', address: 'Fredikanterassi 1, 00520 Helsinki' },
    { country: 'India', number: '+91 877-781-6577', address: 'Mumbai' },
    { country: 'USA', number: '+1 408-915-9253', address: 'San Jose, California' }
  ];

  return (
    <section>
      <LandingTripSection backgroundImage="https://www.desktopbackground.org/download/o/2015/09/06/1007040_aurora-borealis-from-space-gif-wallpaper_1920x1080_h.jpg"
        title="Who We Are"
        targetScrollId="about"
      />

      <div className="glance-container" id='about'>
        <h2><span style={{ color: 'rgb(8, 51, 68)' }}>Nordix Trip</span> at Glance</h2>
        <div className="info-container">
          <div className="info-item">
            <span className="large-text">2020</span>
            <div className="line"></div>
            <strong>Year founded</strong>
          </div>
          <div className="info-item">
            <span className="large-text">3000</span>
            <div className="line"></div>
            <strong>Clients</strong>
          </div>
          <div className="info-item">
            <span className="large-text">10</span>
            <div className="line"></div>
            <strong>Team members</strong>
          </div>
          <div className="info-item">
            <span className="large-text">Infinite</span>
            <div className="line"></div>
            <strong>Conversations</strong>
          </div>
        </div>
      </div>

      <div className='drop-shape'>
        <h2 className='ourFavourites'>Our team</h2>
      </div>
      <section className="platform-workers">
        {/* Worker Cards */}
        <WorkerCard
          name="Victor Mukherjee"
          designation="Guide 3"
          email="victor@nordixtrip.com"
          imageUrl="/photo/victor.jpg"
          location="Helsinki, Finland"
          linkedin="https://www.linkedin.com/in/dr-victor-mukherjee-22883618/"
          link="https://wa.me/358503358000"

        />
        <WorkerCard
          name="Ratna Roy"
          designation="Guide 1"
          email="ratna@nordixtrip.com"
          imageUrl="/photo/ratna.jpg"
          location="Mumbai, India"
          link="https://wa.me/918777816577"
        />
        <WorkerCard
          name="Suman Chatterjee"
          designation="Guide 2"
          email="suman@nordixtrip.com"
          imageUrl="/photo/suman.jpg"
          location="San Jose, California, USA"
          link="https://wa.me/14089159253"
        />

      </section>


      <MissionComponent />



      {/* Platform Workers */}


      <div className="abtWrapper">
        {/* Icon Row */}
        <div className="social-bar">
          <a className="slider-nav-item" href='https://www.facebook.com/profile.php?id=61556110481738'><FaFacebook style={{ color: 'blue' }} /></a>
          <a className="slider-nav-item"><FaTiktok style={{ color: 'blue' }} /></a>
          <a className="slider-nav-item" href='https://twitter.com/nordixtrip'><FaTwitter style={{ color: 'blue' }} /></a>
          <a className="slider-nav-item"><FaLinkedin style={{ color: 'blue' }} /></a>
          <a className="slider-nav-item" href='https://www.instagram.com/nordixtrip/'><RiInstagramFill style={{ color: 'blue' }} /></a>
          <a className="slider-nav-item"><MdAlternateEmail style={{ color: 'blue' }} /></a>
        </div>

        <div className="locations-section">
          {locationsData.map((location, index) => (
            <div className="location-card">
              <h3>{location.country}</h3>
              <p>Contact Phone: {location.number}</p>
              <p>Address: {location.address}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutComponent;
