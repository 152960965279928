import React from 'react'
import '../../../styles/scss/partials/pcLanding.scss';
import { FaFacebook, FaTwitter, FaInstagram, FaTiktok } from 'react-icons/fa';
import QuickEnquiry from '../../core-components/QuickEnquiry';
import TopOffers from '../../carousel-view-offers/views/TopListedOffers';

const NewPCLand = () => {
  return (
    <>
    <div className='landWrapper'>
    <div className="flex-container">
    <div className="social-icons">
        <div className="icon-column">
            <hr/>
            <a href='https://www.instagram.com/nordixtrip/'><FaInstagram className='icon'/></a>
          <a href='https://www.facebook.com/profile.php?id=61556110481738'><FaFacebook className='icon' /></a>
          <a href='https://twitter.com/nordixtrip'><FaTwitter className='icon' /></a>
          <a><FaTiktok className='icon'/></a>
          
        </div>
      </div>
    <div className="left">
      <h2>Welcome to <br/> Nordix Trip</h2>
      <p>Explore amazing content and discover new experiences.</p>
      <button>Explore Now</button>
    </div>
    <div className="right">
      <div className="card" id="card1">
        <img src="/photo/Northern_Light_HomeHeader1.jpg" alt="Card Image" />
        <h3>Hiking</h3>
      </div>
      <div className="card" id="card2">
        <img src="/photo/Helsinki_Cathedral_HomeHeader2.jpg" alt="Card Image" />
        <h3>City Tours</h3>
      </div>
      <div className="card" id="card3">
        <img src="https://img.freepik.com/free-photo/woman-enjoying-beautiful-rural-surroundings_23-2149125483.jpg?w=360&t=st=1703677596~exp=1703678196~hmac=c9439f8fe956e08e35f2d6c12361afb79da944924a808878dab39f30a7394311" alt="Card Image" />
        <h3>Personalized Trips</h3>
      </div>
      <div className="card" id="card4">
        <img src="/photo/Santa_HomeHeader1.jpg" alt="Card Image" />
        <h3>Exclusive packages</h3>
      </div>
    </div>
  </div>
  <QuickEnquiry/>
  </div>
<TopOffers/>
  </>
  )
}

export default NewPCLand