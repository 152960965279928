import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../../styles/css/accordion.css';

const data = {
  rows: [
    {
      title: "How do I book a trip to the Nordics?",
      content: "To book a trip to the Nordics, navigate to our website, choose your desired destination, select your preferred dates, and follow the booking instructions provided. If you have any specific questions, feel free to contact our customer support."
    },
    {
      title: "Are flights included in the travel packages?",
      content: "In most cases, flights are not automatically included in our travel packages to the Nordics. However, some packages may offer the option to include flights. Make sure to check the details of each package for specific inclusions and exclusions."
    },
    {
      title: " What are the must-visit destinations in the Nordics?",
      content: "The Nordics region is known for its stunning landscapes and vibrant cities. Popular destinations include Stockholm, Oslo, Copenhagen, Reykjavik, and Helsinki. Explore our packages to discover the best places to visit in the Nordics."
    },
    {
      title: "How can I customize my Nordic trip itinerary?",
      content: "If you want to customize your Nordic trip itinerary, please contact our travel experts. They can assist you in tailoring your journey based on your preferences, including specific activities, excursions, and accommodations."
    },
    {
      title: "What is the best time to visit the Nordics?",
      content: "The best time to visit the Nordics depends on your preferences. Summer (June to August) offers pleasant weather for outdoor activities, while winter (December to February) is ideal for experiencing the magical Northern Lights. Consider your preferred activities when planning your trip."
    },
  ]
}

const styles = {
    bgColor: '#164863',
    titleTextColor: '#41B06E',
    rowTitleColor: 'white',
    rowContentColor: '#ddd',
    arrowColor: '#ddd',
    padding: '10px',
  };

const FaqContact = () => {
    return (
      <div className='accordion' style={{fontSize:'10px'}}>
        <Faq data={data} styles={styles}/>
      </div>
    )
}
export default FaqContact;