// MissionDescription.js
import React from 'react';

const MissionDescription = () => {
  return (
    <div className="mission-description">
      <h2>Our Mission: Unlock Unforgettable Adventures within Your Budget</h2>
      <p>
        Join us at Nordix Trip as we guide you through the captivating realms of Scandinavia. Our commitment is to craft curated journeys that blend affordability with the essence of Nordic luxury. With our team's extensive global explorations spanning over traveling to 120 countries, we've gathered invaluable insights into the desires of travelers like you. We understand the diverse expectations that accompany each journey and tailor our experiences accordingly. Situated in Finland and Norway, our local expertise ensures seamless operations, guaranteeing you a hassle-free and enriching travel itinerary.
      </p>
    </div>

  );
};

export default MissionDescription;
