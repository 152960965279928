import React from 'react';
import '../../styles/css/landingSection.css'
const LandingTripSection = ({ backgroundImage, title, targetScrollId }) => {
  const scrollToContent = () => {
    if (targetScrollId) {
      const targetElement = document.getElementById(targetScrollId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  
  return (
    <section
      className="landing-body"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      {/* Dark overlay */}
      <div className="landing-body-overlay"></div>

      {/* Content */}
      <div className="landing-content">
        <h2>{title}</h2>
        <hr />
        <div>
          <button onClick={scrollToContent}>Begin your journey</button>
        </div>
      </div>
    </section>
  );
};

export default LandingTripSection;
