import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';
import BookingCard from '../../core-components/BookingCard';


const BookingTemplate = ({ categoryTrips, categoryTitle }) => {
  const [isMobile, setIsMobile] = useState(false);

  const [swiperConfig, setSwiperConfig] = useState({
    modules: [Navigation, A11y],
    spaceBetween: 20,
    slidesPerView: 3,
    navigation: false,
    breakpoints: {
      300: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: -15,
      },
      1035: {
        slidesPerView: 3,
        spaceBetween: -35,
      },
    },
  });

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 600;
      setIsMobile(newIsMobile);

      setSwiperConfig((prevConfig) => ({
        ...prevConfig,
        modules: newIsMobile ? [A11y] : [Navigation, A11y],
        navigation: !newIsMobile,
      }));
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className='drop-shape'>
        <h2 className='ourFavourites'>{categoryTitle}</h2>
      </div>
     

      <section className='sectionOffers'>
       
        <Swiper
          {...swiperConfig}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          {categoryTrips.map((city) => (
            <SwiperSlide key={city.id}>
              
              <BookingCard
                backgroundImage={city.backgroundImage}
                pageLink={city.pageLink}
                title={city.title}
                price={city.details?.price}
                duration={city.details?.duration}
                description={city.details?.description}
                seasons={city.details?.seasons}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      </div>
  );
};

export default BookingTemplate;
