import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { stopLoading } from '../../redux/actions/loadingAction';
import '../../styles/scss/styles.scss';

const Preloader = ({ isLoading, stopLoading }) => {
  const location = useLocation();
  const [displayPreloader, setDisplayPreloader] = useState(true);

  useEffect(() => {
    const shouldDisplayPreloader = location.hash === '#/';
  
    if (shouldDisplayPreloader && isLoading) {
      // Check if it's the first visit
      const isFirstVisit = localStorage.getItem('isFirstVisit');
  
      // Set the timeout duration based on whether it's the first visit or not
      const timeoutDuration = isFirstVisit ? 8000 : 10000;
  
      // Set a flag to check if the content in the preloader is loaded
      let contentLoaded = false;
  
      // Function to check if all content is loaded and stop the preloader
      const checkContentLoaded = () => {
        if (contentLoaded) {
          setDisplayPreloader(false);
          stopLoading(); // Dispatch the stopLoading action
        }
      };
  
      // Attach an event listener to window.onload to check when all content is loaded
      window.onload = () => {
        contentLoaded = true;
        checkContentLoaded();
      };
  
      // Set the flag in local storage after the first visit
      if (!isFirstVisit) {
        localStorage.setItem('isFirstVisit', 'false');
      }
  
      // Set a timeout to ensure the preloader disappears after the specified duration
      const timeoutId = setTimeout(() => {
        checkContentLoaded();
      }, timeoutDuration);
  
      return () => clearTimeout(timeoutId);
    }
  }, [location, isLoading, stopLoading]);

  return displayPreloader ? (
    <div className="preloader">
      <div className='geo-square'>
        <img src="https://i.pinimg.com/originals/eb/70/7a/eb707ae7096cc8df384f1bf87dab547a.gif" alt="" />
      </div>
      <h1 style={{ textAlign:'center', fontSize:'10rem', color:'aliceblue', fontFamily:'Marvin Visions Variable' }}>
        Nordix Trip
      </h1>
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  isLoading: state.loading.isLoading,
});

const mapDispatchToProps = {
  stopLoading, 
};

export default connect(mapStateToProps, mapDispatchToProps)(Preloader);

