import React, {useState, useEffect} from 'react';
import PCNav from './MobNav';
import MobNav from './PCNav';


  const Navbar = () => {
     const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileSize = window.matchMedia('(max-width: 768px)').matches;
      setIsMobile(isMobileSize);
    };

    // Initial check on mount
    handleResize();

    // Add event listener for resizing
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? (
        // Render mobile navbar component
        <PCNav />
      ) : (
        // Render desktop navbar component
       
        <MobNav/>
      )}
    </div>
  );
};

export default Navbar;