// ThemeToggle.js
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import '../../styles/css/toggle-button.css';

const ThemeToggle = () => {
  const { darkMode, toggleTheme } = useTheme();

  return (
    <div className="theme-toggle">
    <input
      type="checkbox"
      id="theme-switch"
      checked={darkMode}
      onChange={toggleTheme}
    />
    <label htmlFor="theme-switch" className="toggle-label" />
  </div>
  );
};

export default ThemeToggle;
