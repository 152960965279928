import React from 'react';
import '../../../styles/css/about-us.css';
import { useTheme } from '../../../contexts/ThemeContext';

const WhyUs = () => {
  const { darkMode } = useTheme();
  const isMobile = window.innerWidth <= 768;

  const galleryPhotos = [
    '/photo/iceland1.jpg',
    '/photo/lofoten.jpg',
    '/photo/northern_light1.jpg',
    '/photo/oslo1.jpg',
    '/photo/reindeer_couple1.jpg',
    '/photo/santa1.jpg',
    '/photo/Stockholm1.jpg',
    '/photo/log_Cabin1.jpg',
    '/public/photo/igloo1.jpg',
  ];

  const imagesToDisplay = isMobile ? galleryPhotos.slice(0, 1) : galleryPhotos.slice(0, 1);

  return (
    <section className='aboutUsSection'>
      <h2>Why Us</h2>
      <div className={`galleryDiv${darkMode ? ' dark-mode' : ''}`}>
        <img src="/photo/log_cabin1.jpg" />
      </div>

      <div className='textDiv'>
        <div className={`text-content${darkMode ? ' dark-mode' : ''}`}>
          <h3>Expertise</h3>
          <p>
            <ul>
              <li>Benefit from our team's extensive knowledge and expertise in Scandinavian travel.</li>
              <li>Rely on our experienced travel experts to curate unique Nordic experiences.</li>
            </ul>
          </p>
        </div>
        <div className={`text-content${darkMode ? ' dark-mode' : ''}`}>
          <h3>Local Insights:</h3>
          <p>
            <ul>
              <li>Access insider tips and recommendations for discovering hidden gems.</li>
              <li>Explore Nordic culture, customs, and traditions with our expert guidance.</li>
            </ul>
          </p>
        </div>
        <div className={`text-content${darkMode ? ' dark-mode' : ''}`}>
          <h3>Quality Accommodations:</h3>
          <p>
            <ul>
              <li>Enjoy high-quality accommodations in picturesque and comfortable settings.</li>
              <li>Stay in reputable hotels, and unique accommodations handpicked by us.</li>
            </ul>
          </p>
        </div>
      </div>

    </section>
  );
};

export default WhyUs;
