import React, {useState, useEffect} from 'react';
import MobileLanding from './MobileLanding';
import NewPCLand from './NewPCLand';


const LandingSection = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileSize = window.matchMedia('(max-width: 768px)').matches;
      setIsMobile(isMobileSize);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? (
        <MobileLanding/>
      ) : (
        <NewPCLand/>
      )}
    </div>
  );
};


export default LandingSection;