import React from 'react';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import BookingCard from '../../core-components/BookingCard';
import '../../../styles/scss/styles.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import specialOffers from '../../../data/booking-data/specialOffers.json';
import BookingTemplate from '../template/CarouselViewTemplate';
import MobileBookingView from '../template/MobileCarousel';

const NorwegianDelights = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {isMobile ? (
                <MobileBookingView categoryTrips={Object.values(specialOffers)} categoryTitle="Exclusive Package" />
            ) : (
                <BookingTemplate categoryTrips={Object.values(specialOffers)} categoryTitle='Exclusive Christmas Package 2024' />
            )}
        </>
    );
};

export default NorwegianDelights