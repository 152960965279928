import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../../../styles/scss/styles.scss';
import BookingTemplate from '../template/CarouselViewTemplate';
import MobileBookingView from '../template/MobileCarousel';

import finnishTrips from '../../../data/booking-data/finnishTrips.json';

const HottestOffers = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <MobileBookingView categoryTrips={Object.values(finnishTrips)} categoryTitle="Winter Wonderland " />
      ) : (
        <BookingTemplate categoryTrips={Object.values(finnishTrips)} categoryTitle='Winter Wonderland : Discover Finland Tours' />
      )}
    </>
  );
};

export default HottestOffers
