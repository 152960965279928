
import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegSnowflake } from "react-icons/fa";
import ThemeToggle from '../core-components/ThemeToggle';

const PCNav = () => {
    return(
        <>
    <nav className="landing-nav">
      <div className="logo">
        <h3>
          <FaRegSnowflake/><br/>Nordix Trip
        </h3>
      </div>
      <div className="nav-elements">
      <ul>
        <li>
          <Link to="/" className="link">
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" className="link">
            About
          </Link>
        </li>
        <li>
          <Link to="/our-tours" className="link">
            Our Tours
          </Link>
        </li>
        <li>
          <Link to="/contact" className="link">
            Contact
          </Link>
        </li>
        <li>
          <Link to="/blog" className="link">
            Blog
          </Link>
        </li>
        <li>
          <ThemeToggle/>
        </li>
      </ul>
    </div>
    </nav> 
    </>
    )
}
export default PCNav