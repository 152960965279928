import React, { useState } from "react";


const Tooltip = ({children, text}) => {
    const [isHovered, setHovered] =  useState(false)
    
    return (
        <div className="tooltip-container" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
          {children}
          {isHovered && <div className="tooltip">{text}</div>}
        </div>
      );

}

export default Tooltip