import React from 'react'
import ContactForm from '../../components/core-components/ContactForm';
import Navbar from '../../components/navs/Navbar';
import Layout from '../../components/Layout';
import SocialIconsFixed from '../../components/core-components/SocialIconsFixed';

const Contact = () => {
  return (
    <Layout>
    <div style={{height:'100%', backgroundImage:'url(https://i.imgur.com/UPhAf15.png)', backgroundRepeat:'no-repeat', backgroundSize:'cover'}}>
        <section style={{display:'flex', justifyContent:'center', margin:'auto', paddingTop:'10rem'}}>
            <ContactForm/>  
        </section>
              
    </div>
    <SocialIconsFixed/>
    </Layout>
  )
}

export default Contact