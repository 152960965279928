import React from 'react';
import Navbar from '../components/navs/Navbar'; 
import Footer from '../components/core-components/Footer'; 

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />

      <div>
        {children}
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
