// MissionComponent.js
import React from 'react';
import MissionDescription from './MissionDescription';
import '../../styles/scss/partials/mission-about.scss';
import { FaMountain } from "react-icons/fa";
import { FaUtensils } from "react-icons/fa";
import { FaLandmark } from "react-icons/fa";
import { FaHome } from "react-icons/fa";



const MissionComponent = () => {
  return (
    <div className="mission-component">
      <MissionDescription />

      <div className="mission-cards">
        <div className="mission-card">
          {/*Adventure icon*/}
          <FaMountain className='icon' />
          <h3>Adventure</h3>
          <p>Embark on thrilling adventures, from riding through majestic trails to chasing the Northern Lights.</p>
        </div>

        <div className="mission-card">
          {/*Local icon*/}
          <FaHome className='icon' />
          <h3>Discover Locally</h3>
          <p>Delve into the authentic essence of each destination with our carefully curated local experiences, led by knowledgeable guides.</p>
        </div>

        <div className="mission-card">
          {/*Culture icon */}
          <FaLandmark className='icon' />
          <h3>Immerse</h3>
          <p>Immerse yourself in the rich tapestry of Nordic culture, from folklore to modern-day traditions, through immersive experiences.</p>
        </div>

        <div className="mission-card">
          {/*Cuisine icon*/}
          <FaUtensils className='icon' />
          <h3>Savor</h3>
          <p>Indulge in delectable Nordic cuisine, savoring the flavors of local delicacies and culinary delights.</p>
        </div>
      </div>


    </div>
  );
};

export default MissionComponent;
