
import React, { lazy, Suspense } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Preloader from './components/core-components/Preloader';


import Home from './pages/main-pages/Home';
import BlogComponent from './pages/main-pages/Blog';
import About from './pages/main-pages/About';
import OurTours from './pages/main-pages/OurTours';
import Contact from './pages/main-pages/Contact';




//lazy loaded minor components
const HelsinkiTour = lazy(() => import('./pages/trip-info/Finland/Helsinki'));
const RovaniemiTour = lazy(() => import('./pages/trip-info/Finland/Rovaniemi'));
const TurkuTour = lazy(() => import('./pages/trip-info/Finland/Turku'));
const LaplandTour = lazy(() => import('./pages/trip-info/Finland/Lapland'));

//sweden
const UppsalaTour = lazy(() => import('./pages/trip-info/Sweden/Uppsala'));
const GoteborgTour = lazy(() => import('./pages/trip-info/Sweden/Goteborg'))
const MalmoTour = lazy(() => import('./pages/trip-info/Sweden/Malmo'))
const StockholmTour = lazy(() => import('./pages/trip-info/Sweden/Stockholm'))
//norway
const OsloTour = lazy(() => import('./pages/trip-info/Norway/Oslo'))
const BergenTour = lazy(() => import('./pages/trip-info/Norway/Bergen'))
const TromsoTour = lazy(() => import('./pages/trip-info/Norway/Tromso'))
const LofotenTour = lazy(() => import('./pages/trip-info/Norway/Lofoten'))
//iceland
const ReykjavikTour = lazy(() => import('./pages/trip-info/Iceland/Reykjavik'))
// denmark
const AarhusTour = lazy(() => import('./pages/trip-info/Denmark/Aarhus'))
const CopenhagenTour = lazy(() => import('./pages/trip-info/Denmark/Copenhagen'))
const OdenseTour = lazy(() => import('./pages/trip-info/Denmark/Odense'))
const RoskildeTour = lazy(() => import('./pages/trip-info/Denmark/Roskilde'))

//trip types
const CityTours = lazy(() => import('./pages/trip-type-sections/CityTours'))
const Hiking = lazy(() => import('./pages/trip-type-sections/Hiking'))
const ExPackages = lazy(() => import('./pages/trip-type-sections/ExPackages'))
const PersonalisedTrips = lazy(() => import('./pages/trip-type-sections/PersonalisedTrips'))

//blog posts
const ExploringCopenhagen = lazy(() => import('./pages/blog-posts/ExploringCph'));
const AuroraNorway = lazy(() => import('./pages/blog-posts/AuroraNorway'));
const StockholmCity = lazy(() => import('./pages/blog-posts/StockholmCity'))
const IcelandFjords = lazy(() => import('./pages/blog-posts/IcelandFjords'))


const PrivPage = lazy(() => import('./components/core-components/PrivacyPage'));



function App() {
  return (
    <Router>
      {/*<Preloader>*/}
      <div className="App">

        <Routes>
          {/*Main pages from nav*/}
          <Route path="/" element={<Home />} />
          <Route path="/our-tours" element={<OurTours />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<BlogComponent />} />
          <Route path="/contact" element={<Contact />} />

          {/*Landing page sections*/}
          <Route path='/hiking' element={<Suspense fallback={<Preloader />}><Hiking /></Suspense>} />
          <Route path='/city-tours' element={<Suspense fallback={<Preloader />}><CityTours /></Suspense>} />
          <Route path='/exclusive-packages' element={<Suspense fallback={<Preloader />}><ExPackages /></Suspense>} />
          <Route path='/personalised-trips' element={<Suspense fallback={<Preloader />}><PersonalisedTrips /></Suspense>} />

          {/*Tours for different trips - FINLAND*/}
          <Route path='/winter-wonderland-special-tour-4d3n' element={<Suspense fallback={<Preloader />}><HelsinkiTour /></Suspense>} />
          <Route path='/nordics-love-adventure-8d7n' element={<Suspense fallback={<Preloader />}><RovaniemiTour /></Suspense>} />
          <Route path='/couple-winter-wanderlust-7d6n' element={<Suspense fallback={<Preloader />}><TurkuTour /></Suspense>} />
          <Route path='/chirstmas-family-tour-7d6n' element={<Suspense fallback={<Preloader />}><LaplandTour /></Suspense>} />

          {/*Tours for different trips - SWEDEN - this will be used for Christmas Special trip*/}
          <Route path='/chirstmas-winter-wonderland1-8d7n' element={<Suspense fallback={<Preloader />}><UppsalaTour /></Suspense>} />
          <Route path='/chirstmas-festive-wonderland-7d6n' element={<Suspense fallback={<Preloader />}><GoteborgTour /></Suspense>} />
          <Route path='/chirstmas-winter-wonderland2-8d7n' element={<Suspense fallback={<Preloader />}><StockholmTour /></Suspense>} />
          <Route path='/malmo-tour' element={<Suspense fallback={<Preloader />}><MalmoTour /></Suspense>} />


          {/*Tours for different trips - NORWAY*/}
          <Route path='/nordics-best-cities-trip-10d9n' element={<Suspense fallback={<Preloader />}><OsloTour /></Suspense>} />
          <Route path='/scandinavian-city-tours-8d7n' element={<Suspense fallback={<Preloader />}><BergenTour /></Suspense>} />
          <Route path='/north-europe-cruise-trip-12d11n' element={<Suspense fallback={<Preloader />}><TromsoTour /></Suspense>} />
          <Route path='/nordics-baltic-best-cities-trips-15d14n' element={<Suspense fallback={<Preloader />}><LofotenTour /></Suspense>} />


          {/*Tours for different trips - ICELAND*/}
          <Route path='/reykjavik-tour' element={<Suspense fallback={<Preloader />}><ReykjavikTour /></Suspense>} />




          {/*DENMARK trips */}
          <Route path='/aarhus-tour' element={<Suspense fallback={<Preloader />}><AarhusTour /></Suspense>} />
          <Route path='/copenhagen-tour' element={<Suspense fallback={<Preloader />}><CopenhagenTour /></Suspense>} />
          <Route path='/odense-tour' element={<Suspense fallback={<Preloader />}><OdenseTour /></Suspense>} />
          <Route path='/roskilde-tour' element={<Suspense fallback={<Preloader />}><RoskildeTour /></Suspense>} />

          {/*BLOG POSTS */}
          <Route path='/exploring-copenhagen' element={<Suspense fallback={<Preloader />}><ExploringCopenhagen /></Suspense>} />
          <Route path='/nordics-northern-lights' element={<Suspense fallback={<Preloader />}><AuroraNorway /></Suspense>} />
          <Route path='/stockholm-on-water' element={<Suspense fallback={<Preloader />}><StockholmCity /></Suspense>} />
          <Route path='/iceland-fjords' element={<Suspense fallback={<Preloader />}><IcelandFjords /></Suspense>} />



          <Route path='/privacy-policy' element={<Suspense fallback={<Preloader />}><PrivPage /></Suspense>} />




        </Routes>
      </div>
      {/*</Preloader>*/}

    </Router>
  );
}

export default App;



