import React, { useState } from 'react';
import '../../styles/scss/styles.scss';
import { FaWhatsapp } from 'react-icons/fa';

const QuickEnquiry = () => {
  const [showContactPanel, setShowContactPanel] = useState(false);

  const handleEnquiryButtonClick = () => {
    setShowContactPanel(!showContactPanel);
  };

  const handleCloseButtonClick = () => {
    setShowContactPanel(false);
  };

  return (
    <>
      <button className={`enquiry-button ${showContactPanel ? 'open-panel' : ''}`} onClick={handleEnquiryButtonClick}>
        Quick Enquiry
      </button>

      <div className={`contact-panel ${showContactPanel ? 'open-panel' : ''}`}>
        <button className="close-button" onClick={handleCloseButtonClick}>
          &#x2715;
        </button>
        <div className="whatsapp-section">
          <button>
            <a
              href="https://wa.me/358503358000"
              target="_blank"
              rel="noopener noreferrer"
            >
              Send us a message
            </a>
          </button>
          <a
            href="https://wa.me/123456789"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="whatsapp-icon" />
          </a>

        </div>
        <hr />
        <div className="contact-info-section">
          <h3>Need to call us?</h3>
          <div className="contact-number">
            <div className="country-code">(Finland)</div>
            <div className="phone-details">
              <div className="phone-number"> <FaWhatsapp /> +358 503358000</div>
              <button className="send-message-button">
                <a
                  href="https://wa.me/358503358000"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Send Message
                </a>
              </button>
              <div className="hours-available">Available 9 AM - 5 PM</div>
            </div>
          </div>
          <div className="contact-number">
            <div className="country-code">(India)</div>
            <div className="phone-details">
              <div className="phone-number"><FaWhatsapp /> +91 8777816577</div>
              <button className="send-message-button">
                <a
                  href="https://wa.me/918777816577"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Send Message
                </a>
              </button>
              <div className="hours-available">Available 10 AM - 6 PM</div>
            </div>
          </div>
          <div className="contact-number">
            <div className="country-code">(USA)</div>
            <div className="phone-details">
              <div className="phone-number"> <FaWhatsapp /> +1 408 9159253</div>
              <button className="send-message-button">
                <a
                  href="https://wa.me/14089159253"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Send Message
                </a>
              </button>
              <div className="hours-available">Available 8 AM - 4 PM</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuickEnquiry;
