import React from 'react'
import OurToursComponent from '../../components/our-tours/OurToursComponent'
import SocialIconsFixed from '../../components/core-components/SocialIconsFixed'

const OurTours = () => {
  return (
    <div>
        <OurToursComponent/>
        <SocialIconsFixed/>
    </div>
  )
}

export default OurTours